/**
 * アンケート送信前の確認ダイアログコンポーネント
 * 
 * アンケートの回答内容を確認し、送信を実行するためのダイアログを提供します。
 * - 回答内容のプレビュー表示
 * - 必須項目の表示
 * - 送信/キャンセル操作
 * - 送信中のローディング表示
 * @package sdpro-customer-app
 * @module components/survey
 * @version 1.0.0
 * @since 2025/02/09
 * 
 */
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogOverlay,
    Box,
    Button,
    Flex,
    Stack,
    Text,
  } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import { Survey } from 'smartdishpro-scripts/ts_common';
  
// 送信前の確認ダイアログのProps
  type Props = {
    isOpen: boolean;
    onClose: () => void;
    cancelRef: MutableRefObject<HTMLInputElement>;
    survey: Survey | undefined;
    pendingFormValues: { [key: string]: string | string[] | undefined } | null;
    onSubmit: () => void;
  };
  
/**
 * 送信前の確認ダイアログコンポーネント
 * 
 * @param {Props} props - Props
 * @returns {JSX.Element} 送信前の確認ダイアログコンポーネント
 */
  export const SurveyConfirmDialog = ({
    isOpen,
    onClose,
    cancelRef,
    survey,
    pendingFormValues,
    onSubmit,
  }: Props) => {
    // 確認用のコンポーネントを内部に移動
    const SurveyConfirmContent = ({ answers }: { answers: { [key: string]: string | string[] | undefined } }) => {
      return (
        <Stack spacing={4}>
          {survey?.questions.map((_question) => {
            const answer = answers[_question.id];
            return (
              <Box
                key={_question.id}
                p={4}
                border="1px"
                borderColor="gray.200"
                borderRadius="md"
                backgroundColor="gray.50"
              >
                <Text fontWeight="bold" fontSize="sm" color="gray.700">
                  {_question.question}
                  {_question.required && (
                    <Text as="span" color="red.400" ml={1}>※</Text>
                  )}
                </Text>
                <Text
                  mt={2}
                  fontSize="sm"
                  color="gray.900"
                  p={2}
                  bg="white"
                  borderRadius="sm"
                >
                  {Array.isArray(answer)
                    ? answer.join(', ')
                    : answer || '未入力'}
                </Text>
              </Box>
            );
          })}
        </Stack>
      );
    };
  
    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        size="xs"
        scrollBehavior="inside"
      >
        <AlertDialogOverlay />
        <AlertDialogContent p="2" maxH={window.innerHeight * 0.8} bg="white">
          <Text
            textAlign="center"
            fontSize="lg"
            fontWeight="bold"
            p={4}
            position="sticky"
            top={0}
            bg="white"
            zIndex={1}
            borderBottom="1px"
            borderColor="gray.200"
          >
            以下の内容で送信しますか？
          </Text>
  
          <Box maxH={window.innerHeight * 0.7} overflowY="auto" px={6} py={4}>
            <SurveyConfirmContent answers={pendingFormValues || {}} />
          </Box>
  
          <Flex
            justifyContent="space-between"
            mt="5"
            gap={4}
            px={6}
            pb={4}
            position="sticky"
            bottom={0}
            bg="white"
            borderColor="gray.200"
          >
            <Button
              variant="outline"
              colorScheme="black"
              onClick={() => {
                onClose();
              }}
              size="md"
              _hover={{
                bg: 'gray.50'
              }}
              w="120px"
            >
              キャンセル
            </Button>
            <Button
              colorScheme="teal"
              onClick={onSubmit}
              w="120px"
            >
              送信
            </Button>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>
    );
  };